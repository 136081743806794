import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddEditMonitoringCompanyComponent } from '../shared/components/add-edit-monitoring-company/add-edit-monitoring-company.component';
import { protectGuard } from '../shared/guards/protect.guard';
import { AppUserFeedbackComponent } from './pages/app-user-feedback/app-user-feedback.component';
import { AddEditCheckpointComponent } from './pages/checkpoints/add-edit-checkpoint/add-edit-checkpoint.component';
import { CheckpointsComponent } from './pages/checkpoints/checkpoints.component';
import { ViewCheckpointComponent } from './pages/checkpoints/view-checkpoint/view-checkpoint.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { ViewClientComponent } from './pages/clients/view-client/view-client.component';
import { CompanyProfileComponent } from './pages/company-profile/company-profile.component';
import { MessageComponent } from './pages/hol-and-chat/chat/message/message.component';
import { AddEditHandOverLogComponent } from './pages/hol-and-chat/hand-over-log/hand-over-log/add-edit-hand-over-log/add-edit-hand-over-log.component';
import { HandOverLogComponent } from './pages/hol-and-chat/hand-over-log/hand-over-log/hand-over-log.component';
import { HomeComponent } from './pages/home/home.component';
import { IncidentTypeComponent } from './pages/incident-type/incident-type.component';
import { AddIncidentComponent } from './pages/incidents/add-incident/add-incident.component';
import { IncidentsComponent } from './pages/incidents/incidents.component';
import { ViewIncidentComponent } from './pages/incidents/view-incident/view-incident.component';
import { AddJobComponent } from './pages/jobs/add-job/add-job.component';
import { JobDetailsComponent } from './pages/jobs/job-details/job-details.component';
import { JobsComponent } from './pages/jobs/jobs.component';
import { PatrolDetailComponent } from './pages/jobs/patrol-detail/patrol-detail.component';
import { MissedEventsComponent } from './pages/missed-events/missed-events.component';
import { MonitoringCompanyComponent } from './pages/monitoring-company/monitoring-company.component';
import { PagesComponent } from './pages/pages.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { ResponseTypeComponent } from './pages/response-type/response-type.component';
import { RosterScheduleComponent } from './pages/roster-schedule/roster-schedule.component';
import { ServiceTypeComponent } from './pages/service-type/service-type.component';
import { SitesComponent } from './pages/sites/sites.component';
import { ViewSiteComponent } from './pages/sites/view-site/view-site.component';
import { SosAlertsComponent } from './pages/sos-alerts/sos-alerts.component';
import { SubscriptionComponent } from './pages/subscription/subscription.component';
import { UserGroupComponent } from './pages/user-group/user-group.component';
import { AddEditUserPromptComponent } from './pages/user-prompts/add-edit-user-prompt/add-edit-user-prompt.component';
import { UserPromptsComponent } from './pages/user-prompts/user-prompts.component';
import { UserUpdateLogComponent } from './pages/user-update-log/user-update-log.component';
import { GuardsOnlineComponent } from './pages/users/guards-online/guards-online.component';
import { LicencesComponent } from './pages/users/licences/licences.component';
import { UsersComponent } from './pages/users/users.component';
import { ProfileComponent } from './profile/profile.component';

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    canActivate: [protectGuard],
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'dashboard',
        component: HomeComponent,
      },

      {
        path: 'jobs',
        component: JobsComponent,
      },

      {
        path: 'add-job',
        component: AddJobComponent,
      },

      {
        path: 'job-detail/:jobKey',
        component: JobDetailsComponent,
      },

      {
        path: 'client',
        pathMatch: 'full',

        component: ClientsComponent,
      },
      {
        path: 'view-client',
        component: ViewClientComponent,
      },

      {
        path: 'view-route',
        component: PatrolDetailComponent,
      },

      {
        path: 'profile',
        component: ProfileComponent,
      },

      {
        path: 'company-profile',
        component: CompanyProfileComponent,
      },

      {
        path: 'subscription',
        component: SubscriptionComponent,
      },

      {
        path: 'users',
        component: UsersComponent,
      },
      {
        path: 'guard-licences',
        component: LicencesComponent,
      },
      {
        path: 'user-groups',
        component: UserGroupComponent,
      },
      {
        path: 'checkpoints',
        component: CheckpointsComponent,
      },
      {
        path: 'add-checkpoint',
        component: AddEditCheckpointComponent,
      },
      {
        path: 'view-checkpoints',
        component: ViewCheckpointComponent,
      },

      {
        path: 'user-events',
        component: UserUpdateLogComponent,
      },

      {
        path: 'reports',
        component: ReportsComponent,
      },
      {
        path: 'support',
        component: AppUserFeedbackComponent,
      },
      {
        path: 'response-type',
        component: ResponseTypeComponent,
      },
      {
        path: 'service-type',
        component: ServiceTypeComponent,
      },

      {
        path: 'incident-type',
        component: IncidentTypeComponent,
      },
      {
        path: 'monitoring-company',
        component: MonitoringCompanyComponent,
      },
      {
        path: 'view-company',
        component: AddEditMonitoringCompanyComponent,
      },
      {
        path: 'add-company',
        component: AddEditMonitoringCompanyComponent,
      },
      {
        path: 'incidents',
        component: IncidentsComponent,
      },
      {
        path: 'add-incident',
        component: AddIncidentComponent,
      },
      {
        path: 'view-incident',
        component: ViewIncidentComponent,
      },

      {
        path: 'roster',
        component: RosterScheduleComponent,
      },

      {
        path: 'guards',
        component: GuardsOnlineComponent,
      },
      {
        path: 'missed-events',
        component: MissedEventsComponent,
      },
      {
        path: 'sos-alerts',
        component: SosAlertsComponent,
      },

      {
        path: 'user-prompts',
        component: UserPromptsComponent,
      },
      {
        path: 'user-prompt',
        component: AddEditUserPromptComponent,
      },
      {
        path: 'sites',
        pathMatch: 'full',

        component: SitesComponent,
      },
      {
        path: 'view-site',
        component: ViewSiteComponent,
      },
      {
        path: 'hand-over-log',
        component: HandOverLogComponent,
      },
      {
        path: 'add-hand-over-log',
        component: AddEditHandOverLogComponent,
      },
      { path: 'view-hand-over-log', component: AddEditHandOverLogComponent },
      {
        path: 'message',
        component: MessageComponent,
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
