<div class="mb-2 fw-bold">
  Add Job
  <span class="cursor-pointer">
    {{ selectedStatus?.name ? " > " + selectedStatus?.name : "" }}</span
  >
</div>
<div class="row row-cols-1 row-cols-lg-2">
  <div class="col">
    <!-- Form begins -->
    <form [formGroup]="responseFormData">
      <!-- Site name input -->

      <div class="row row-cols-2 my-3">
        <div class="col">
          <button
            type="button"
            class="btn btn-lg btn-dark w-100"
            [routerLink]="['/jobs']"
            [queryParams]="{ cancel: true }"
          >
            Cancel
          </button>
        </div>
        <div class="col">
          <button
            type="button"
            class="btn btn-primary btn-lg w-100"
            [disabled]="!responseJobType.site.data || addJobPressed"
            (click)="onAddJob()"
          >
            Add Job
          </button>
        </div>
      </div>

      <div *ngIf="responseJobType.site.data?.id" class="mb-2">
        <dl>
          <div class="d-flex align-items-center">
            <div class="flex-grow-1">
              <dt>Site</dt>
              <dd>
                {{ responseJobType.site.value }}
                <div>[{{ responseJobType.client.value }}]</div>
              </dd>
            </div>
            <div>
              <span (click)="editField('site')">
                <i
                  class="fa-solid fa-pen-to-square md-font-size text-secondary"
                ></i
              ></span>
            </div>
          </div>
        </dl>
      </div>
      <div class="mb-3" *ngIf="!responseJobType.site.data?.id">
        <gtapp-auto-complete
          [data]="siteList"
          placeHolder="Select Site"
          searchKeyword="company_name"
          [initialValue]="responseFormData.value.siteName"
          [itemTemplate]="siteTemplate"
          [addInitialKey]="isAdmin || isDispatchUser"
          (inputChanged)="onSiteSearch($event)"
          (selected)="onSelectSite($event)"
          [inputId]="'siteId'"
          [enableCloseOption]="true"
          (fieldCleared)="editField('site')"
        >
        </gtapp-auto-complete>
      </div>

      <ng-template #siteTemplate let-item>
        @if (item?.key === 0) {
        <div>Add New Site</div>
        } @else {
        <div>
          <div class="fw-medium">
            {{ item?.company_name }} [{{ item?.company?.company_name }}]
          </div>

          <div class="small-font text-secondary">
            {{ item?.address?.full_address }}
          </div>
        </div>
        }
      </ng-template>

      <ng-container *ngIf="responseJobType.site.data">
        <!-- Monitoring Company -->
        <div *ngIf="responseJobType.mc.data?.id" class="mb-2">
          <dl>
            <div class="d-flex align-items-center">
              <div class="flex-grow-1">
                <dt>Monitoring Company</dt>
                <dd>
                  {{ responseJobType.mc.value }}
                </dd>
              </div>
              <div>
                <span (click)="editField('mc')">
                  <i
                    class="fa-solid fa-pen-to-square md-font-size text-secondary"
                  ></i
                ></span>
              </div>
            </div>
          </dl>
        </div>
        <div class="mb-3" *ngIf="!responseJobType.mc.data?.id">
          <gtapp-auto-complete
            [data]="monitoringCompanyList"
            placeHolder="Monitoring Company"
            searchKeyword="company_name"
            [initialValue]="responseFormData.value.monitoringCompanyName"
            [itemTemplate]="MCTemplate"
            [addInitialKey]="isAdmin && responseJobType.mc.tempValue"
            (inputChanged)="onMcSearch($event)"
            (selected)="onMonitoringSelect($event)"
            [inputId]="'mcId'"
            [enableCloseOption]="true"
            (fieldCleared)="editField('mc')"
          >
          </gtapp-auto-complete>
        </div>
        <ng-template #MCTemplate let-item>
          @if (item?.key === 0) {
          <div>Add Monitoring Company</div>
          } @else {
          <div>
            {{ item.company_name }}
          </div>
          }
        </ng-template>
        <!-- Assign Guard -->
        <div *ngIf="selectedStatus.enable_assignees === true">
          <div *ngIf="responseJobType.assignee.data?.id" class="mb-2">
            <dl>
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <dt>Assigned To</dt>
                  <dd>
                    {{ responseFormData.value.assigneeName }}
                  </dd>
                </div>
                <div>
                  <span (click)="editField('assignee')">
                    <i
                      class="fa-solid fa-pen-to-square md-font-size text-secondary"
                    ></i
                  ></span>
                </div>
              </div>
            </dl>
          </div>
          <div class="mb-3" *ngIf="!responseJobType.assignee.data?.id">
            <gtapp-auto-complete
              [data]="assigneeList"
              placeHolder="Assign To"
              searchKeyword="full_name"
              [initialValue]="responseFormData.value.assigneeName"
              [itemTemplate]="assigneeTemplate"
              (inputChanged)="onAssigneeSearch($event)"
              (selected)="onAssigneeSelect($event)"
              [inputId]="'assigneeId'"
              [enableCloseOption]="true"
              (fieldCleared)="editField('assignee')"
            >
            </gtapp-auto-complete>
          </div>
          <ng-template #assigneeTemplate let-item>
            <div>
              {{ item?.full_name }}
              <small *ngIf="!item?.validStateLicense">
                <i class="fa-solid fa-shield-halved"></i>
              </small>
            </div>
          </ng-template>
        </div>
        <!-- Response Type -->
        <div *ngIf="responseJobType.rt.data?.id" class="mb-2">
          <dl>
            <div class="d-flex align-items-center">
              <div class="flex-grow-1">
                <dt>Response Type</dt>
                <dd>
                  {{ responseJobType.rt.value }}
                </dd>
              </div>
              <div>
                <span (click)="editField('rt')">
                  <i
                    class="fa-solid fa-pen-to-square md-font-size text-secondary"
                  ></i
                ></span>
              </div>
            </div>
          </dl>
        </div>
        <div class="mb-3" *ngIf="!responseJobType.rt.data?.id">
          <gtapp-auto-complete
            [data]="responseTypeList"
            placeHolder="Select Response Type"
            searchKeyword="name"
            [initialValue]="responseFormData.value.responseTypeName"
            [itemTemplate]="responseTypeTemplate"
            [addInitialKey]="isAdmin && responseJobType.rt.tempValue"
            (inputChanged)="onRtSearch($event)"
            (selected)="onResponseTypeSelect($event)"
            [inputId]="'rtId'"
            [enableCloseOption]="true"
            (fieldCleared)="editField('rt')"
          >
          </gtapp-auto-complete>
          <ng-template #responseTypeTemplate let-item>
            @if (item?.key === 0) {
            <div>Add Response Type</div>
            } @else {
            <div>
              {{ item.name }}
            </div>
            }
          </ng-template>
        </div>

        <div class="row mb-3">
          <div
            class="col"
            *ngIf="!largeView"
            (click)="openTemplate(addInfoDialogRef)"
          >
            <button
              class="btn btn-lg w-100"
              [ngClass]="
                responseJobType.ai.value ? 'btn-info' : 'btn-outline-info'
              "
            >
              Additional Information
            </button>
          </div>
          <div class="col" *ngIf="largeView">
            <textarea
              class="form-control"
              placeholder="Additional Information"
              cols="20"
              rows="10"
              wrap="hard"
              maxlength="900"
              style="width: 100%"
              type="text"
              [(ngModel)]="responseJobType.ai.value"
              formControlName="requestDetail"
            ></textarea>
          </div>
        </div>
        <!-- Final actions -->
        <div class="form-floating mb-3">
          <input
            class="form-control"
            id="mobeIField"
            nbInput
            type="text"
            placeholder="Ext. Job ID"
            formControlName="externalJobId"
            autocomplete="off"
          />
          <label>Ext. Job ID</label>
        </div>
        <div *ngIf="responseJobType.oc.data?.id" class="mb-2">
          <dl>
            <div class="d-flex align-items-center">
              <div class="flex-grow-1">
                <dt>Site Contact</dt>
                <dd>
                  {{ responseJobType.oc.value }}
                </dd>
              </div>
              <div>
                <span (click)="editField('oc')">
                  <i
                    class="fa-solid fa-pen-to-square md-font-size text-secondary"
                  ></i
                ></span>
              </div>
            </div>
          </dl>
        </div>
        <div class="mb-3" *ngIf="!responseJobType.oc.data?.id">
          <gtapp-auto-complete
            [data]="siteContacts"
            placeHolder="Onsite Contact (Optional)"
            searchKeyword="full_name"
            [initialValue]="responseFormData.value.siteContactName"
            [itemTemplate]="siteContactSearch"
            [addInitialKey]="true"
            (selected)="selectContact($event)"
            [inputId]="'ocId'"
            [enableCloseOption]="true"
            (fieldCleared)="editField('oc')"
          >
          </gtapp-auto-complete>
        </div>
        <ng-template #siteContactSearch let-item>
          @if (item?.key === 0) {
          <div *ngIf="isAdmin && responseJobType.site.data">
            Add New Contact
          </div>
          } @else {
          <div>
            {{ item?.full_name }}
          </div>
          }
        </ng-template>
      </ng-container>
    </form>
  </div>

  <div class="col">
    <div
      *ngIf="
        showSiteMap &&
        (responseJobType.site?.data?.address?.latitude ||
          responseJobType.site?.data?.gps?.lat) &&
        (responseJobType.site?.data?.address?.longitude ||
          responseJobType.site?.data?.gps?.lon) &&
        largeView
      "
    >
      <gtapp-map-leaflet
        [latLon]="{
          lat:
            responseJobType.site?.data?.address?.latitude ||
            responseJobType.site?.data?.gps?.lat,
          lon:
            responseJobType.site?.data?.address?.longitude ||
            responseJobType.site?.data?.gps?.lon
        }"
        [mapId]="'largeJob'"
      ></gtapp-map-leaflet>
    </div>
  </div>
</div>

<!-- Additional Information POPOVER / DIALOG -->
<ng-template #addInfoDialogRef let-data let-addInfoDialogRef="dialogRef">
  <div class="dialog-box card">
    <div class="card-header">Additional Information</div>

    <!-- Request Detail Section start-->
    <div class="row">
      <div class="col">
        <textarea
          class="form-control"
          placeholder="Additional Information"
          cols="20"
          rows="10"
          wrap="hard"
          maxlength="900"
          type="text"
          [(ngModel)]="responseJobType.ai.value"
        ></textarea>
      </div>
    </div>

    <div class="row my-2">
      <div class="col">
        <button
          class="btn btn-lg w-100"
          [ngClass]="responseJobType.ai.value ? 'btn-primary' : 'btn-dark'"
          (click)="addInfoDialogRef.close(); addAI()"
        >
          {{ responseJobType.ai.value ? "Save" : "Close" }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<!-- License Reminder Check Template -->
<ng-template
  #licenceCheckTemplateRef
  let-data
  let-licenceCheckTemplateRef="dialogRef"
>
  <div class="card dialog-box">
    <div class="card-header">
      WARNING

      <span
        class="float-end"
        (click)="licenceCheckTemplateRef.close(); shiftFocus('assigneeId')"
        ><i class="fa-solid fa-xmark"></i
      ></span>
    </div>
    <div>
      <div class="alert alert-danger">
        You have not added any security licences for
        {{ responseJobType?.site?.data?.address?.state_code }}

        <div class="mt-2">
          Licences can be added in
          <a
            (click)="ignoreLicenseCheck(); licenceCheckTemplateRef.close()"
            routerLink="/company-profile"
            class="link-primary"
          >
            Company Profile
          </a>
        </div>
      </div>

      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          id="licenseCheckbox"
          (change)="licenseReminderField = !licenseReminderField"
        />
        <label class="form-check-label" for="licenseCheckbox">
          Don't show this again
        </label>
      </div>

      <div class="row row-cols-2 mt-4">
        <div class="col">
          <button
            class="btn btn-secondary btn-lg w-100"
            (click)="
              ignoreLicenseCheck();
              licenceCheckTemplateRef.close();
              shiftFocus('assigneeId')
            "
          >
            Ignore
          </button>
        </div>
        <div class="col">
          <button
            class="btn btn-primary btn-lg w-100"
            (click)="showLicensePopup(); licenceCheckTemplateRef.close()"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
