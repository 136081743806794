import { formatDate } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  dateRangeValueConverter,
  getFormattedDate,
  getFormattedRepeatDetail,
  isLargeScreen,
  isLargeScreenFun,
} from '../../../../global.variable';
import {
  getEventAssigneeList,
  getEventAssignees,
  getJobStatus,
  trashIconIf,
} from '../../../../roster-common-functions';
import { AppService } from '../../../app.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { DatetimePickerComponent } from '../../../shared/components/datetime-picker/datetime-picker.component';
import { ModelDialogueService } from '../../../shared/components/modal-dialogue/model-dialogue.service';
import { SchedulerComponent } from '../../../shared/components/scheduler/scheduler.component';
import { DataCheckService } from '../../../shared/services/data-check.service';
import { LoadingSpinnerService } from '../../../shared/services/loading-spinner.service';
import { OrientationLockService } from '../../../shared/services/orientationlock.service';
import { ToasterService } from '../../../shared/services/toaster.service';
import { JobsService } from '../jobs/jobs.service';
import { RosterScheduleService } from './roster-schedule.service';

@Component({
  selector: 'gtapp-roster-schedule',
  templateUrl: './roster-schedule.component.html',
  styleUrl: './roster-schedule.component.scss',
})
export class RosterScheduleComponent implements OnInit, OnDestroy {
  @ViewChild('jobDetailTemplateRef') jobDetailTemplateRef: any =
    TemplateRef<any>;
  startDay = new Date();
  endDay: any;
  weekDays: any;
  groupBy: string = 'company';
  dialogRef: any;
  userData: any;
  isAdmin: boolean = false;
  isDispatchUser: boolean = false;
  subscriberUserId: any;

  mobileRosterData: any;
  mobileselectedDate: any;

  // new section

  rosterScheduleData: any = [];
  mobileRosterScheduleData: any = [];
  statusData: any;
  cardStyling: any = { status: {}, groupStyle: {} };

  today = new Date();

  deleteOptionSelected: number = 1;

  conflictData: any = [];
  guardsList: any = [];

  desktopDayDuration: number = 0;

  private navigationEndSubscription: Subscription;

  searchResults: any = [];
  searchResultsBackup: any = [];
  tempSearchResults: any = [];
  searchDateRange: any;
  dateRange: any = {
    start: new Date(new Date().setDate(new Date().getDate() - 1)),
    end: new Date(new Date().setDate(new Date().getDate() + 1)),
  };
  schedulerForm: UntypedFormGroup = new UntypedFormGroup({
    end_time: new FormControl(null),
    start_time: new FormControl(null),
    start_day: new FormControl(new Date()),
    end_day: new FormControl(null, Validators.required),
    repeat_times: new UntypedFormControl(null),
    repeat_type: new UntypedFormControl(null, Validators.required),
    repeat_details: new UntypedFormControl([]),
    repeat_interval: new UntypedFormControl(1, [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
    ]),
    position: new UntypedFormControl(null, []),
    week_day: new UntypedFormControl(null, []),
    month_repeat_case: new UntypedFormControl(null, []),
  });
  groupByData: any;

  assineeNameValue: string = '';
  dateRangeValue: any = '';

  largeView: Boolean = isLargeScreen;
  isLargeScreenSubscription: Subscription = new Subscription();

  largeScreenDateSelected: any = getFormattedDate();

  isAnimating = false;
  startX: number = 0;

  weatherData: any = {};
  weatherAddressInfo: any = {};

  constructor(
    private spinnerService: LoadingSpinnerService,
    private jobService: JobsService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: ModelDialogueService,
    private appService: AppService,
    private dataCheckService: DataCheckService,
    private rsService: RosterScheduleService,
    private viewContainerRef: ViewContainerRef,
    private toasterService: ToasterService,
    private orientationService: OrientationLockService,
    private cdr: ChangeDetectorRef
  ) {
    let userData = this.appService.getUserData();
    this.desktopDayDuration = userData?.preferences?.rosterWeekLength || 3;
    if (sessionStorage.getItem('redirectDate')) {
      this.getStartDay(sessionStorage.getItem('redirectDate'));
      sessionStorage.removeItem('redirectDate');
    }
    this.route.params.subscribe({
      next: (params) => {
        if (this.route.snapshot.fragment) {
          this.getStartDay(this.route.snapshot.fragment);
        }
      },
    });
    this.navigationEndSubscription = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        window.onpopstate = () => {
          this.route.fragment.subscribe((fragment) => {
            if (fragment) this.formatMobileRosterSchedules(fragment);
          });
        };
      }
    });
  }
  updateWeekNumber(event: any) {
    if (event.target.value != this.desktopDayDuration) {
      this.desktopDayDuration = Number(event.target.value);
      this.updatePreferences(this.desktopDayDuration);
      this.getEndDay();
      this.getRosterSchedules();
    }
  }
  getStartDay(date: any) {
    this.mobileselectedDate = new Date(date);
    let date1 = this.mobileselectedDate;
    date1.setHours(0, 0, 0, 0);
    let date2 = new Date();
    date2.setHours(0, 0, 0, 0);
    let weekDays: any =
      (date1.getTime() - date2.getTime()) / (24 * 60 * 60 * 1000);
    let startDay = new Date();
    const weekLength = this.largeView ? this.desktopDayDuration + 1 : 7;

    startDay.setDate(
      startDay.getDate() + weekLength * Math.floor(weekDays / weekLength)
    );

    this.startDay = startDay;
  }

  ngOnInit(): void {
    this.isLargeScreenSubscription =
      this.orientationService.isLargeScreen.subscribe(async (event: any) => {
        if (event) {
          this.largeView = await isLargeScreenFun();
          this.spinnerService.show();
          this.getRosterSchedules();
        }
      });

    this.userData = this.appService.getUserData();

    this.isAdmin = this.dataCheckService.isUserAdmin();
    this.isDispatchUser = this.dataCheckService.isDispatchUser();

    this.subscriberUserId = this.userData?.subscriber?.subscriber_user_id;
    this.getEndDay();

    setTimeout(() => {
      this.spinnerService.show();
      this.getRosterSchedules();
    }, 500);

    this.getJobStatuses();
    this.getWeatherInfo();
  }
  getWeatherInfo() {
    const body = {
      subscriber_address: 1,
    };
    this.jobService.getWeatherInfo(body).subscribe((response: any) => {
      if (response?.status === 'success') {
        this.weatherData = response?.data?.forecast_weather;
        this.weatherAddressInfo = response?.address_data;
      }
    });
  }

  getEndDay() {
    let endDay = new Date(this.startDay.getTime());
    const days = this.largeView ? this.desktopDayDuration : 6;

    endDay.setDate(this.startDay.getDate() + days);
    this.endDay = endDay;
  }

  generateWeekDays(starDay: any, endDay: any) {
    const week = [];
    let startDate = new Date(starDay);
    startDate.setHours(0, 0, 0, 0);

    while (startDate <= endDay) {
      week.push(new Date(startDate));
      startDate.setDate(startDate.getDate() + 1);
    }

    return week;
  }
  getDay(dateString: string) {
    const date = new Date(dateString);
    let weekDays = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    return this.largeView
      ? weekDays[date.getDay()]
      : weekDays[date.getDay()]?.slice(0, 2);
  }

  previousWeek() {
    this.mobileselectedDate = null;
    let startDay = new Date(this.startDay.getTime());
    startDay.setDate(
      this.startDay.getDate() -
        (this.largeView ? this.desktopDayDuration + 1 : 7)
    );
    this.startDay = startDay;
    this.getEndDay();
    this.getRosterSchedules();
    const rosterDays = this.getRosterDays();
    if (rosterDays.length > 0) {
      this.mobileselectedDate = rosterDays[rosterDays.length - 1]; // Set to the last day
    }
  }
  getRosterDays(): Date[] {
    const rosterDays: Date[] = [];
    let currentDay = new Date(this.startDay);
    while (currentDay <= this.endDay) {
      rosterDays.push(new Date(currentDay));
      currentDay.setDate(currentDay.getDate() + 1);
    }

    return rosterDays;
  }
  nextWeek() {
    this.mobileselectedDate = null;
    let startDay = new Date(this.startDay.getTime());
    startDay.setDate(
      this.startDay.getDate() +
        (this.largeView ? this.desktopDayDuration + 1 : 7)
    );
    this.startDay = startDay;
    this.getEndDay();
    this.getRosterSchedules();
  }

  nextDay() {
    const endDay = this.formatDateWithoutTime(this.endDay);
    const mobileselectedDate = this.formatDateWithoutTime(
      new Date(this.mobileselectedDate)
    );
    if (mobileselectedDate === endDay) {
      // implies we need to call the api for the next week
      this.nextWeek();
    } else {
      const selectedDate = new Date(this.mobileselectedDate);
      const nextDate = selectedDate.setDate(selectedDate.getDate() + 1);
      this.formatMobileRosterSchedules(nextDate);
    }
  }
  previousDay() {
    const startDay = this.formatDateWithoutTime(this.startDay);

    const mobileselectedDate = this.formatDateWithoutTime(
      new Date(this.mobileselectedDate)
    );
    if (mobileselectedDate === startDay) {
      // implies we need to call the api for the prev week
      this.previousWeek();
    } else {
      const selectedDate = new Date(this.mobileselectedDate);
      const nextDate = selectedDate.setDate(selectedDate.getDate() - 1);
      this.formatMobileRosterSchedules(nextDate);
    }
  }
  animateTransition() {
    this.isAnimating = true;
    setTimeout(() => {
      this.isAnimating = false;
      // this.animationDirection = null;
    }, 300);
  }

  onTouchStart(event: TouchEvent) {
    this.startX = event.touches[0].clientX;
  }

  onTouchEnd(event: TouchEvent) {
    const endX = event.changedTouches[0].clientX;
    const threshold = 75;
    if (this.startX - endX > threshold) {
      this.nextWeek();
      this.animateTransition();
    } else if (endX - this.startX > threshold) {
      this.previousWeek();
      this.animateTransition();
    }
  }

  formatMobileRosterSchedules(dateTime: any) {
    let date = formatDate(dateTime, 'yyyy-MM-dd', 'en');
    this.mobileselectedDate = date;

    this.router.navigate(['/roster'], {
      fragment: this.mobileselectedDate,
    });

    this.mobileRosterScheduleData = [];

    this.rosterScheduleData?.forEach((schedule: any) => {
      if (
        schedule?.event_dates?.includes(date) &&
        (this.isAdmin || this.isDispatchUser
          ? true
          : this.isGuardPresentCheck(schedule, date)) &&
        (schedule?.repeat_type !== 'once' ||
          (schedule?.repeat_type == 'once' &&
            formatDate(new Date(schedule?.start_day), 'yyyy-MM-dd', 'en') ===
              date))
      ) {
        this.mobileRosterScheduleData.push(schedule);
      }
    });
    this.mobileRosterScheduleData?.sort((a: any, b: any) => {
      let timeA = new Date('1970-01-01T' + a.start_time + 'Z');
      let timeB = new Date('1970-01-01T' + b.start_time + 'Z');
      return timeA.getTime() - timeB.getTime();
    });
  }
  isGuardPresentCheck(scheduleData: any, date: any) {
    let assignees = getEventAssigneeList(scheduleData, date);

    return assignees?.some(
      (assignee: any) => assignee?.id == this.subscriberUserId
    );
  }

  showHourByData(hour: number, scheduleData: any) {
    let [startHour, startMin, startSec] = scheduleData?.start_time
      ?.split(':')
      ?.map(Number);
    let [endHour, endMin, endSec] = scheduleData?.end_time
      ?.split(':')
      ?.map(Number);

    // Convert times to minutes past midnight
    let startMinutes = startHour * 60 + startMin;
    let endMinutes = endHour * 60 + endMin;
    let hourMinutes = hour * 60;

    if (
      (startMinutes <= hourMinutes && hourMinutes < endMinutes) ||
      (startMinutes < hourMinutes + 60 && hourMinutes + 60 <= endMinutes)
    ) {
      return true;
    } else {
      return false;
    }
  }

  getDateStr(date: any) {
    return formatDate(new Date(date), 'yyyy-MM-dd', 'en_US');
  }
  openJobDetailPage(scheduleData: any, date: any) {
    this.spinnerService.show();
    this.rsService
      .createRosterScheduleJob({
        job_id: this.getJobId(scheduleData, date),
        event_date: this.getDateStr(date),
      })
      .then((response: any) => {
        // this.formatData(response);
        if (response?.status === 'success') {
          this.router.navigateByUrl(`/job-detail/${response?.data?.job_key}`, {
            state: response?.data?.job_id,
          });
          window.localStorage.setItem('urlId', response?.data?.job_id);
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }

        this.spinnerService.hide();
      });
  }
  openEventDetailPage(
    scheduleData: any,
    date: any,
    template: TemplateRef<any>
  ) {
    if (
      trashIconIf(scheduleData, date, this.isAdmin || this.isDispatchUser) &&
      scheduleData?.repeat_type !== 'once'
    ) {
      this.dialogRef = this.dialogService.open(
        template,
        {
          data: { schedule: scheduleData, date: date },
        },
        this.viewContainerRef
      );
    } else {
      if (scheduleData?.job_details) {
        this.spinnerService.show();
        this.openJobDetailPage(scheduleData, date);
      } else {
        this.spinnerService.show();
        this.openPatrolRoutePage(scheduleData, date);
      }
    }
  }
  openPatrolRoutePage(scheduleData: any, date: any) {
    this.spinnerService.show();
    this.rsService
      .createRosterSchedulePatrol({
        patrol_route_id: this.getPatrolId(scheduleData, date),
        event_date: this.getDateStr(date),
      })
      .then((response: any) => {
        // this.formatData(response);
        if (response?.status === 'success') {
          this.spinnerService.show();
          this.router.navigate([
            '/view-route',
            {
              rKey: String(response?.data?.patrol_route_id),
            },
          ]);
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }

        this.spinnerService.hide();
      });
  }
  isNotPastDate(scheduleData: any, date: any) {
    let startDateTime: any = new Date(date);
    if (scheduleData?.repeat_type == 'once') {
      startDateTime = new Date(
        scheduleData?.start_day + ' ' + scheduleData?.start_time
      );
    } else {
      this.setTime(startDateTime, scheduleData?.start_time);
    }

    return Boolean(startDateTime < new Date());
  }
  addExceptionDate(scheduleData: any, date?: any) {
    this.spinnerService.show();
    const formattedEventDate = formatDate(
      new Date(date || this.mobileselectedDate),
      'yyyy-MM-dd',
      'en_US'
    );
    let body: any = { exception_dates: [formattedEventDate] };
    if (scheduleData?.job_details?.job_type) {
      body.job_id = this.getJobId(scheduleData, date);
    }
    this.rsService
      .updateRosterSchedule(scheduleData?.id, body, { remove_date: 1 })
      .then((response: any) => {
        // this.formatData(response);
        if (response?.status === 'success') {
          this.getRosterSchedules();
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }

        this.spinnerService.hide();
      });
  }
  arraysHaveSameItems(arr1: any[], arr2: any[]): boolean {
    // Create sets from the arrays
    const set1 = new Set(arr1);
    const set2 = new Set(arr2);

    if (set1.size !== set2.size) {
      return false;
    }

    return Array.from(set1).every((item) => set2.has(item));
  }

  formatRosterScheduleData(response: any) {
    this.dialogRef?.close();
    this.weekDays = this.generateWeekDays(this.startDay, this.endDay);

    if (response?.status == 'success') {
      if (response?.data) {
        this.rosterScheduleData = response?.data;
        if (this.rosterScheduleData?.length) {
          this.appService.updateOnBoardingValue('patrolJob');
        }
        if (this.isAdmin || this.isDispatchUser) {
          this.findWeeklyAssigneeConflicts();
        }

        const formattedWeekDays = this.weekDays?.map((date: any) =>
          this.formatDateWithoutTime(new Date(date))
        );

        this.groupByData = response?.group_by_data?.filter((group: any) => {
          return group.event_data.some((event: any) => {
            if (event.repeat_type === 'once') {
              return (
                formattedWeekDays.includes(
                  this.formatDateWithoutTime(new Date(event.start_day))
                ) &&
                (this.isAdmin || this.isDispatchUser
                  ? true
                  : formattedWeekDays.some((date: any) =>
                      this.isGuardPresentCheck(event, date)
                    ))
              );
            } else {
              return formattedWeekDays.some(
                (date: any) =>
                  event?.event_dates?.includes(
                    formatDate(new Date(date), 'yyyy-MM-dd', 'en_US')
                  ) &&
                  (this.isAdmin || this.isDispatchUser
                    ? true
                    : this.isGuardPresentCheck(event, date))
              );
            }
          });
        });
        this.groupByData.forEach((groupItem: any) => {
          groupItem?.event_data?.sort((a: any, b: any) => {
            let timeA = new Date('1970-01-01T' + a.start_time + 'Z');
            let timeB = new Date('1970-01-01T' + b.start_time + 'Z');
            return timeA.getTime() - timeB.getTime();
          });
        });

        if (!this.largeView) {
          this.formatMobileRosterSchedules(
            this.mobileselectedDate || this.startDay
          );
        }
      }
    } else {
      this.toasterService.setMessage({
        successMessage: '',
        errorMessage: response['message'],
      });
    }
  }
  getRosterSchedules() {
    let reqParams: any = {
      start_day: formatDate(new Date(this.startDay), 'yyyy-MM-dd', 'en_US'),
      end_day: formatDate(new Date(this.endDay), 'yyyy-MM-dd', 'en_US'),
    };
    if (this.largeView) {
      reqParams.group_by = this.groupBy;
    }

    this.spinnerService.show();
    this.rsService.getRosterSchedules(reqParams).subscribe((response: any) => {
      this.formatRosterScheduleData(response);

      this.spinnerService.hide();
    });
  }

  addStaticSchedule(date: any) {
    if (this.isAdmin || this.isDispatchUser) {
      this.router.navigate(['/add-job'], { fragment: 'roster' });
      sessionStorage.setItem(
        'redirectDate',
        date || this.mobileselectedDate || new Date()
      );
      sessionStorage.setItem(
        'selectedRosterDate',
        date || this.mobileselectedDate || new Date()
      );
    }
  }

  formatRepeatDetail(scheduleData: any) {
    return getFormattedRepeatDetail(
      scheduleData?.repeat_type,
      scheduleData?.repeat_details
    );
  }
  getJobStatuses() {
    this.jobService.getJobParams().subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.statusData = response.statuses;

        this.statusData.push({
          id: '0',
          name: 'Closed',
          additional_info: {
            style: {
              snubbed: {
                'color': 'var(--color-basic-1100)',
                'background-color': 'var(--color-basic-100)',
                'border': '1px solid var(--color-basic-1100)',
                'border-radius': '5px',
                'font-size': 'small',
                'padding-left': '2rem',
                'padding-right': '2rem',
              },
            },
          },
        });
      }

      this.statusData?.forEach((element: any) => {
        this.cardStyling['status'][element?.name] = {
          'color': `${element.additional_info?.style?.snubbed?.color}`,
          'background-color': `${element.additional_info?.style?.snubbed['background-color']}`,
          'border': `1px solid ${element.additional_info?.style?.snubbed['background-color']}`,
          'border-radius': '5px',
          'font-size': 'small',
          'padding-left': '2rem',
          'padding-right': '2rem',
        };

        this.cardStyling['groupStyle'][element?.name] = {
          'border-color': `${element.additional_info?.style?.snubbed['border-color']}`,
        };
      });

      this.cardStyling['status']['Not Started'] = {
        'color': 'var(--bs-white)', // Bootstrap white text
        'background-color': 'var(--bs-warning)', // Bootstrap warning background
        'border': '1px solid var(--bs-warning)',
        'border-radius': '5px',
        'font-size': 'small',
        'padding-left': '2rem',
        'padding-right': '2rem',
      };
      this.cardStyling['status']['In Progress'] = {
        'color': 'var(--bs-white)', // Bootstrap white text
        'background-color': 'var(--bs-success)', // Bootstrap success background
        'border': '1px solid var(--bs-success)',
        'border-radius': '5px',
        'font-size': 'small',
        'padding-left': '2rem',
        'padding-right': '2rem',
      };
      this.cardStyling['status']['Late to Shift'] = {
        'color': 'var(--bs-white)', // Bootstrap white text
        'background-color': 'var(--bs-danger)', // Bootstrap danger background
        'border': '1px solid var(--bs-danger)',
        'border-radius': '5px',
        'font-size': 'small',
        'padding-left': '2rem',
        'padding-right': '2rem',
      };
      this.cardStyling['status']['Shift Complete'] = {
        'color': 'var(--bs-white)', // Bootstrap white text
        'background-color': 'var(--bs-dark)', // Bootstrap dark background
        'border': '1px solid var(--bs-dark)',
        'border-radius': '5px',
        'font-size': 'small',
        'padding-left': '2rem',
        'padding-right': '2rem',
      };
    });
  }
  avoidConflictforCancelledJobs(scheduleData: any, date: any) {
    return this.statusData?.some(
      (status: any) =>
        status.name === getJobStatus(scheduleData, date) &&
        [2, 3].includes(status?.status_identity?.value)
    );
  }

  getScheduleSpan(scheduleData: any) {
    if (scheduleData?.repeat_type == 'once') {
      return 3;
    }
    return 1;
  }

  getWelfareCheckTime(scheduleData: any, date: any) {
    return scheduleData?.patrol_route_details
      ? scheduleData?.patrol_route_details?.schedule_details?.interval_check
        ? scheduleData?.patrol_route_details?.schedule_details?.interval_check
        : date
        ? scheduleData?.patrol_route_details?.schedule_details?.[
            formatDate(new Date(date), 'yyyy-MM-dd', 'en_US')
          ]?.interval_check
        : ''
      : scheduleData?.job_details?.schedule_details?.interval_check
      ? scheduleData?.job_details?.schedule_details?.interval_check
      : date
      ? scheduleData?.job_details?.schedule_details?.[
          formatDate(new Date(date), 'yyyy-MM-dd', 'en_US')
        ]?.interval_check
      : '';
  }
  getJobId(scheduleData: any, date: any) {
    return scheduleData?.job_details?.schedule_details?.job_id
      ? scheduleData?.job_details?.schedule_details?.job_id
      : date
      ? scheduleData?.job_details?.schedule_details?.[
          formatDate(new Date(date), 'yyyy-MM-dd', 'en_US')
        ]?.job_id
      : scheduleData?.job_details?.job_id;
  }
  getPatrolId(scheduleData: any, date: any) {
    return scheduleData?.patrol_route_details?.schedule_details?.patrol_route_id
      ? scheduleData?.patrol_route_details?.schedule_details?.patrol_route_id
      : date
      ? scheduleData?.patrol_route_details?.schedule_details?.[
          formatDate(new Date(date), 'yyyy-MM-dd', 'en_US')
        ]?.patrol_route_id
      : scheduleData?.patrol_route_details?.patrol_route_id;
  }

  getOnceWeekDayArray(scheduleData: any) {
    if (scheduleData?.event_dates?.length === 1) {
      return this.weekDays;
    } else {
      let eventDates = JSON.parse(JSON.stringify(scheduleData?.event_dates));
      eventDates.shift();

      let newArray = this.weekDays?.filter(
        (item: any) =>
          !eventDates?.includes(
            formatDate(new Date(item), 'yyyy-MM-dd', 'en_US')
          )
      );

      return newArray;
    }
  }
  getStartEndDateTimeArray(scheduleData: any, date: any) {
    const getTime = (date: Date, time: string) => {
      const [hours, minutes] = time.split(':').map(Number);
      date.setHours(hours, minutes);
      return date;
    };

    if (scheduleData?.repeat_type === 'once') {
      const startTime = new Date(
        scheduleData?.start_day + ' ' + scheduleData?.start_time
      );
      const endTime = new Date(
        scheduleData?.end_day + ' ' + scheduleData?.end_time
      );

      return [[startTime, endTime]];
    }

    const startTime = getTime(new Date(date), scheduleData?.start_time);
    const endTime = getTime(new Date(date), scheduleData?.end_time);

    if (scheduleData?.start_time > scheduleData?.end_time) {
      const startDate = new Date(date);
      startDate.setDate(startDate.getDate() - 1);
      const startTimeYesterDay = getTime(startDate, scheduleData?.start_time);
      const endTimeToday = endTime;
      const startTimeToday = startTime;
      const endDate = new Date(date);
      endDate.setDate(endDate.getDate() + 1);
      const endTimeTomorrow = getTime(endDate, scheduleData?.end_time);
      return [
        [startTimeYesterDay, endTimeToday],
        [startTimeToday, endTimeTomorrow],
      ];
    }

    return [[startTime, endTime]];
  }

  setTime(date: Date, time: string) {
    const [hours, minutes] = time?.split(':');
    date.setHours(parseInt(hours), parseInt(minutes));
  }

  openDatePicker() {
    var element = <HTMLInputElement>document.getElementById('dateTimePicker');
    if (element) {
      element.showPicker();
    }
  }

  openTemplate(template: TemplateRef<any>, scheduleData: any, date: any) {
    let startDateTime: any = new Date(date);
    if (scheduleData?.repeat_type == 'once') {
      startDateTime = new Date(
        scheduleData?.start_day + ' ' + scheduleData?.start_time
      );
    } else {
      this.setTime(startDateTime, scheduleData?.start_time);
    }

    if (!trashIconIf(scheduleData, date, this.isAdmin || this.isDispatchUser)) {
      this.toasterService.setMessage({
        successMessage: '',
        errorMessage: 'Selected Date has been passed',
      });
      return;
    }
    this.dialogRef = this.dialogService.open(
      template,
      {
        data: { ...scheduleData, selectedDate: date },
      },
      this.viewContainerRef
    );
  }
  deleteScheduleEvents(scheduleData: any) {
    let exceptionDate = scheduleData?.selectedDate || this.mobileselectedDate;

    if (
      !trashIconIf(
        scheduleData,
        exceptionDate,
        this.isAdmin || this.isDispatchUser
      )
    ) {
      this.toasterService.setMessage({
        successMessage: '',
        errorMessage: 'Selected Date has been passed',
      });
      return;
    }
    if (this.deleteOptionSelected == 1) {
      this.addExceptionDate(scheduleData, scheduleData?.selectedDate);
    } else {
      this.spinnerService.show();
      const formattedEventDate = formatDate(
        new Date(scheduleData?.selectedDate || this.mobileselectedDate),
        'yyyy-MM-dd',
        'en_US'
      );
      let body: any = { end_date: formattedEventDate };
      if (scheduleData?.job_details?.job_type) {
        body.job_id = this.getJobId(scheduleData, formattedEventDate);
      }
      this.rsService
        .updateRosterSchedule(scheduleData?.id, body, { change_end_date: 1 })
        .then((response: any) => {
          // this.formatData(response);
          if (response?.status === 'success') {
            this.getRosterSchedules();
          } else {
            this.toasterService.setMessage({
              successMessage: '',
              errorMessage: response['message'],
            });
          }

          this.spinnerService.hide();
        });
    }
  }
  hasEventOnDay(day: any, grpEvent: any) {
    return grpEvent?.event_data?.some(
      (schedule: any) =>
        schedule?.event_dates?.includes(
          formatDate(new Date(day), 'yyyy-MM-dd', 'en_US')
        ) &&
        (this.isAdmin ||
          this.isDispatchUser ||
          this.isGuardPresentCheck(schedule, day)) &&
        (schedule?.repeat_type !== 'once' ||
          (schedule?.repeat_type == 'once' &&
            formatDate(new Date(day), 'yyyy-MM-dd', 'en_US') ===
              formatDate(new Date(schedule?.start_day), 'yyyy-MM-dd', 'en_US')))
    );
  }

  async parseDailySchedules(day: any) {
    const assigneeDict: any = {};
    const conflictDict: any = {};

    await Promise.all(
      this.rosterScheduleData.map(async (scheduleData: any) => {
        if (
          scheduleData?.event_dates?.includes(
            formatDate(new Date(day), 'yyyy-MM-dd', 'en_US')
          ) &&
          !this.avoidConflictforCancelledJobs(scheduleData, day)
        ) {
          const assignees = getEventAssignees(
            scheduleData,
            day,
            this.isAdmin || this.isDispatchUser
          );
          if (assignees?.length) {
            const timigList = this.getStartEndDateTimeArray(scheduleData, day);

            await Promise.all(
              timigList.map((startEndTiming: any) => {
                assignees.map(async (assignee: any) => {
                  if (assignee?.id in assigneeDict) {
                    assigneeDict[assignee.id].forEach((timing: any) => {
                      if (
                        startEndTiming[0].getTime() <
                          timing.endTime.getTime() &&
                        timing.startTime.getTime() < startEndTiming[1].getTime()
                      ) {
                        if (assignee.id in conflictDict) {
                          if (
                            !conflictDict[assignee.id][
                              'conflictSchedules'
                            ].includes(scheduleData)
                          ) {
                            conflictDict[assignee.id]['conflictSchedules'].push(
                              scheduleData
                            );
                          }
                        } else {
                          conflictDict[assignee.id] = {
                            assigneeData: assignee,
                            conflictSchedules: [
                              scheduleData,
                              timing.scheduleData,
                            ],
                          };
                        }
                      }

                      assigneeDict[assignee.id].push({
                        startTime: startEndTiming[0],
                        endTime: startEndTiming[1],
                        scheduleData: scheduleData,
                      });
                    });
                  } else {
                    assigneeDict[assignee.id] = [
                      {
                        startTime: startEndTiming[0],
                        endTime: startEndTiming[1],
                        scheduleData: scheduleData,
                      },
                    ];
                  }
                });
              })
            );
          }
        }
      })
    );

    return conflictDict;
  }

  async findWeeklyAssigneeConflicts() {
    this.conflictData = [];

    const now = new Date();
    now.setHours(0, 0, 0, 0);
    await Promise.all(
      this.weekDays.map(async (day: any) => {
        if (
          this.formatDateWithoutTime(day) >= this.formatDateWithoutTime(now)
        ) {
          const conflicts = await this.parseDailySchedules(day);
          if (Object.keys(conflicts)?.length) {
            this.conflictData.push({ eventDate: day, conflictInfo: conflicts });
          }
        }
      })
    );
  }
  openConflictTemplate(template: TemplateRef<any>) {
    this.dialogRef = this.dialogService.open(
      template,
      {},
      this.viewContainerRef
    );
  }
  getConflictAssigneeInfo(value: any) {
    return value?.assigneeData;
  }

  validLicenseCheck(license: any, eventData: any) {
    return (
      license.find(
        (lic: any) =>
          lic.issuer_state === eventData?.job_details?.site_address?.state_code
      ) || false
    );
  }

  getGuardDetails(guardsData: any, eventData: any) {
    let exisitingAssignees = getEventAssignees(
      eventData,
      eventData?.selectedDate,
      this.isAdmin || this.isDispatchUser
    )?.map((item: any) => item?.id);
    return guardsData.filter((guard: any) => {
      let validLicense = this.validLicenseCheck(guard.license, eventData);
      if (validLicense) {
        guard.validStateLicense = validLicense;
      }
      return !exisitingAssignees.includes(guard.id);
    });
  }

  getAssignees(event: any = null, eventData: any) {
    if (event.target.value?.length > 2) {
      this.searchAssignees(event.target.value, eventData);
    }
  }
  searchAssignees(searchStr: string = '', eventData: any) {
    this.jobService
      .getAssignees({ full_name: searchStr })
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.guardsList = this.getGuardDetails(response['data'], eventData);
          this.cdr.detectChanges();
        }
      });
  }

  reassigneGuards(
    template: TemplateRef<any>,
    scheduleData: any,
    date: any,
    conflictAssignee: any
  ) {
    this.guardsList = [];
    const data = {
      ...scheduleData,
      selectedDate: date,
      conflictAssignee: conflictAssignee,
    };
    this.dialogRef = this.dialogService.open(
      template,
      {
        data: data,
      },
      this.viewContainerRef
    );
    this.shiftFocus('conflictAssigneeId');

    this.searchAssignees('', data);
  }
  shiftFocus(elementId: string) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);
      element?.focus();
    }, 200);
  }
  onAssigneeSelect(assignee: any, eventData: any) {
    if (
      assignee?.license?.some(
        (item: any) =>
          item.issuer_state === eventData?.job_details?.site_address?.state_code
      ) ||
      assignee?.status === 'ASSIGNED'
    ) {
      assignee.status = 'ASSIGNED';

      this.reassignGuard(
        eventData,
        eventData?.selectedDate,
        eventData?.conflictAssignee,
        assignee
      );
    } else {
      let dialogMsg: any = '';
      if (assignee.id === this.subscriberUserId) {
        dialogMsg =
          'You do not hold a licence for this location, are you sure you want to take the job?';
      } else {
        dialogMsg = `${
          assignee.full_name || assignee.email
        } does not hold a licence for this location, are you sure you want assign to ${
          assignee.full_name || assignee.email
        }?`;
      }
      const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
        data: {
          title: 'Add Assignee',
          message: dialogMsg,
        },
      });
      dialogRef.afterClosed().subscribe((value) => {
        if (value === true) {
          this.reassignGuard(
            eventData,
            eventData?.selectedDate,
            eventData?.conflictAssignee,
            assignee
          );
        }
      });
    }
  }
  reassignGuard(
    eventData: any,
    eventDate: any,
    removeAssignee: any,
    addAssignee?: any
  ) {
    const formattedEventDate = formatDate(
      new Date(eventDate || this.mobileselectedDate),
      'yyyy-MM-dd',
      'en_US'
    );
    if (eventData?.job_details) {
      this.spinnerService.show();

      this.rsService
        .createRosterScheduleJob({
          job_id: this.getJobId(eventData, eventDate),
          event_date: formattedEventDate,
        })
        .then((response: any) => {
          if (response?.status === 'success') {
            let promises = [];
            if (removeAssignee?.id) {
              promises.push(
                this.updateParticularJob(
                  { remove_assignee: removeAssignee.id },
                  response?.data?.job_id
                )
              );
            }
            if (addAssignee?.id) {
              promises.push(
                this.updateParticularJob(
                  { new_assignee: addAssignee.id },
                  response?.data?.job_id
                )
              );
            }
            Promise.all(promises).then(() => {
              setTimeout(() => {
                this.spinnerService.hide();
                this.dialogRef?.close();
                this.getRosterSchedules();
              }, 1000); // delay of 1 seconds
            });
          } else {
            this.toasterService.setMessage({
              successMessage: '',
              errorMessage: response['message'],
            });
            this.spinnerService.hide();
          }
        });
    } else {
      this.spinnerService.show();
      this.rsService
        .createRosterSchedulePatrol({
          patrol_route_id: this.getPatrolId(eventData, eventDate),
          event_date: this.getDateStr(formattedEventDate),
        })
        .then((response: any) => {
          // this.formatData(response);
          if (response?.status === 'success') {
            let existingAssignees = getEventAssignees(
              eventData,
              eventData?.selectedDate,
              this.isAdmin || this.isDispatchUser
            )?.map((item: any) => item?.id);

            existingAssignees.push(addAssignee?.id);
            existingAssignees = existingAssignees?.filter(
              (_id: any) => _id !== removeAssignee?.id
            );

            let updateData: any = {
              patrol_route_id: response?.data?.patrol_route_id,
              assignees: [...new Set(existingAssignees)],
            };
            let reqParams: any = { update_assignees: 1 };

            this.rsService
              .managePatrolRoute(updateData, reqParams)
              .then((response: any) => {
                if (response?.status === 'success') {
                  setTimeout(() => {
                    this.spinnerService.hide();
                    this.dialogRef?.close();
                    this.getRosterSchedules();
                  }, 1000); // delay of 1 seconds
                } else {
                  this.toasterService.setMessage({
                    successMessage: '',
                    errorMessage: response['message'],
                  });
                  this.spinnerService.hide();
                }
              });
          } else {
            this.toasterService.setMessage({
              successMessage: '',
              errorMessage: response['message'],
            });
            this.spinnerService.hide();
          }
        });
    }
  }
  updateParticularJob(body: any, jobId: any) {
    let requestData: any = {
      method: 'put',
      url: 'api/job/' + jobId + '/',
      id: jobId,
      data: body,
    };

    this.jobService.updateJob(requestData).then((response: any) => {
      if (response['status'] == 'success') {
        this.toasterService.setMessage({
          // successMessage: response['message'],
          errorMessage: '',
        });
      } else {
        this.spinnerService.hide();
      }
    });
  }
  formatDateWithoutTime = (date: any) => {
    date.setHours(0, 0, 0, 0);
    return date.getTime();
  };
  isGuardInConflict(date: any, guardId: any, scheduleData: any) {
    const formattedDate = this.formatDateWithoutTime(new Date(date));
    const onceStartTime = this.formatDateWithoutTime(
      new Date(`${scheduleData?.start_day} ${scheduleData?.start_time}`)
    );
    const endStartTime = this.formatDateWithoutTime(
      new Date(`${scheduleData?.end_day} ${scheduleData?.end_time}`)
    );

    return this.conflictData?.some((conflict: any) => {
      const formattedEventDate = this.formatDateWithoutTime(
        new Date(conflict?.eventDate)
      );
      const conflictSchedules =
        conflict?.conflictInfo?.[guardId]?.conflictSchedules;

      if (scheduleData?.repeat_type === 'once') {
        return (
          onceStartTime <= formattedEventDate &&
          endStartTime >= formattedEventDate &&
          conflictSchedules?.some(
            (schedule: any) => schedule?.id === scheduleData?.id
          )
        );
      } else {
        return (
          formattedEventDate === formattedDate &&
          conflictSchedules?.some(
            (schedule: any) => schedule?.id === scheduleData?.id
          )
        );
      }
    });
  }
  isScheduleInConflict(date: any, scheduleData: any) {
    const formattedDate = this.formatDateWithoutTime(new Date(date));
    const onceStartTime = this.formatDateWithoutTime(
      new Date(`${scheduleData?.start_day} ${scheduleData?.start_time}`)
    );
    const endStartTime = this.formatDateWithoutTime(
      new Date(`${scheduleData?.end_day} ${scheduleData?.end_time}`)
    );

    return this.conflictData?.some((conflict: any) => {
      const formattedEventDate = this.formatDateWithoutTime(
        new Date(conflict?.eventDate)
      );
      return Object.entries(conflict?.conflictInfo).some(
        ([k, v]: [string, any]) => {
          return v?.conflictSchedules?.some((schedule: any) => {
            if (schedule?.id !== scheduleData?.id) {
              return false;
            }
            if (scheduleData?.repeat_type === 'once') {
              return (
                onceStartTime <= formattedEventDate &&
                endStartTime >= formattedEventDate
              );
            }
            return formattedEventDate === formattedDate;
          });
        }
      );
    });
  }

  ngOnDestroy(): void {
    if (this.navigationEndSubscription) {
      this.navigationEndSubscription.unsubscribe();
    }
    window.onpopstate = null;

    this.isLargeScreenSubscription.unsubscribe();
  }
  showDeletedSchedules(template: TemplateRef<any>) {
    this.dialogRef = this.dialogService.open(
      template,
      {},
      this.viewContainerRef
    );
  }
  editSchedule(scheduleData: any, date: any) {
    this.schedulerForm.patchValue(scheduleData);
    this.schedulerForm.controls['start_day'].setValue(
      getFormattedDate(new Date(date))
    );
    this.dialogRef = this.dialogService.open(SchedulerComponent, {
      data: { schedulerForm: this.schedulerForm, editSchedule: true },
    });
    this.dialogRef.afterClosed().subscribe((value: any) => {
      if (value) {
        const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
          data: {
            title: 'Are you sure?',
          },
        });
        dialogRef.afterClosed().subscribe((dialogResponse) => {
          if (dialogResponse === true) {
            this.updateSchedule(value, scheduleData, date);
          }
        });
      }
    });
  }
  getUpdateScheduleParams(scheduleData: any) {
    let params: any = {};

    const startTime = new Date(
      scheduleData?.start_day + ' ' + scheduleData?.start_time
    ).getTime();

    const paramKey =
      startTime < new Date().getTime() ? 'started_schedule' : 'future_schedule';
    params[paramKey] = 1;
    return params;
  }
  updateSchedule(scheduleFormData: any, scheduleData: any, date: any) {
    this.spinnerService.show();

    let body: any = scheduleFormData;
    if (scheduleData?.patrol_route_details) {
      body.patrol_route_id = this.getPatrolId(scheduleData, date);
    } else {
      body.job_id = this.getJobId(scheduleData, date);
    }
    this.rsService
      .updateRosterSchedule(
        scheduleData?.id,
        body,
        this.getUpdateScheduleParams(scheduleData)
      )
      .then((response: any) => {
        if (response?.status === 'success') {
          this.getRosterSchedules();
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }

        this.spinnerService.hide();
      });
  }
  updatePreferences(weekLength: number) {
    let userData = this.appService.getUserData();
    if (userData?.preferences) {
      userData.preferences.rosterWeekLength = weekLength;
      this.appService.setUserData(userData);
    }
  }
  handleDateChange(event: any) {
    if (event?.end) {
      this.searchDateRange = event;
      let reqParams: any = {
        start_day: formatDate(
          new Date(this.searchDateRange?.start),
          'yyyy-MM-dd',
          'en_US'
        ),
        end_day: formatDate(
          new Date(this.searchDateRange?.end),
          'yyyy-MM-dd',
          'en_US'
        ),
      };

      this.spinnerService.show();
      this.rsService
        .getRosterSchedules(reqParams)
        .subscribe((response: any) => {
          this.searchResultsBackup = JSON.parse(JSON.stringify(response?.data));
          this.searchJobs(response?.data);

          this.spinnerService.hide();
        });
    }
  }
  async findDaysJobs(date: any, searchString?: any) {
    let indDay = formatDate(new Date(date), 'yyyy-MM-dd', 'en_US');
    if (searchString) {
      return await Promise.all(
        this.tempSearchResults?.filter(
          (scheduleData: any) =>
            this.filterJobResults(scheduleData, indDay, searchString) &&
            scheduleData?.event_dates?.includes(indDay) &&
            (this.isAdmin ||
              this.isDispatchUser ||
              this.isGuardPresentCheck(scheduleData, date)) &&
            (scheduleData?.repeat_type !== 'once' ||
              (scheduleData?.repeat_type == 'once' &&
                formatDate(
                  new Date(scheduleData?.start_day),
                  'yyyy-MM-dd',
                  'en'
                ) === date))
        )
      );
    } else {
      return await Promise.all(
        this.tempSearchResults?.filter(
          (scheduleData: any) =>
            scheduleData?.event_dates?.includes(indDay) &&
            (this.isAdmin ||
              this.isDispatchUser ||
              this.isGuardPresentCheck(scheduleData, date)) &&
            (scheduleData?.repeat_type !== 'once' ||
              (scheduleData?.repeat_type == 'once' &&
                formatDate(
                  new Date(scheduleData?.start_day),
                  'yyyy-MM-dd',
                  'en'
                ) === date))
        )
      );
    }
  }
  async searchJobs(responseData: any, searchString?: any) {
    this.searchResults = [];
    this.tempSearchResults = JSON.parse(JSON.stringify(responseData));

    for (let i = 0; i <= this.getNoOfDays(); i++) {
      let startDate = new Date(this.searchDateRange?.start);
      startDate.setDate(startDate.getDate() + i);
      const dayJobs = await this.findDaysJobs(startDate, searchString);

      if (dayJobs?.length) {
        this.searchResults.push({
          eventDate: startDate,
          dayJobs: dayJobs,
        });
      }
    }
  }
  filterJobResults(scheduleData: any, indDay: any, searchString: any) {
    const jobType = scheduleData?.patrol_route_details
      ? 'Patrol'
      : scheduleData?.job_details?.job_type;
    return (
      scheduleData?.job_details?.company_name
        ?.toLowerCase()
        .includes(searchString?.toLowerCase()) ||
      scheduleData?.job_details?.site_name
        ?.toLowerCase()
        .includes(searchString?.toLowerCase()) ||
      scheduleData?.patrol_route_details?.name
        ?.toLowerCase()
        .includes(searchString?.toLowerCase()) ||
      getJobStatus(scheduleData, indDay)
        ?.toLowerCase()
        .includes(searchString?.toLowerCase()) ||
      jobType?.toLowerCase().includes(searchString?.toLowerCase()) ||
      getEventAssigneeList(scheduleData, indDay)?.some((user: any) =>
        user?.full_name?.toLowerCase().includes(searchString?.toLowerCase())
      )
    );
  }

  filterJobs(event: any) {
    let filteteredResults;
    if (event.target.value) {
      filteteredResults = this.searchResultsBackup?.filter(
        (scheduleData: any) =>
          scheduleData?.job_details?.company_name
            ?.toLowerCase()
            .includes(event.target?.value?.toLowerCase()) ||
          scheduleData?.job_details?.site_name
            ?.toLowerCase()
            .includes(event.target?.value?.toLowerCase()) ||
          scheduleData?.patrol_route_details?.name
            ?.toLowerCase()
            .includes(event.target?.value?.toLowerCase())
      );
    } else {
      filteteredResults = this.searchResultsBackup;
    }
    this.searchJobs(this.searchResultsBackup, event.target.value);
  }
  getNoOfDays() {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const firstDate = new Date(this.searchDateRange?.start).getTime();
    const secondDate = new Date(this.searchDateRange?.end).getTime();

    return Math.round(Math.abs((firstDate - secondDate) / oneDay));
  }
  openSearchTemplate(template: TemplateRef<any>) {
    this.dialogRef = this.dialogService.open(
      template,
      {
        data: {},
      },
      this.viewContainerRef
    );
  }
  openRangePicker() {
    const dialogRef = this.dialogService.open(DatetimePickerComponent, {
      data: {
        dateRange: this.dateRange,
      },
    });
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value !== 'close') {
        this.dateRange = value;
        this.dateRangeValue = `${dateRangeValueConverter(
          this.dateRange?.start
        )} - \n ${dateRangeValueConverter(this.dateRange?.end)}`;

        this.handleDateChange(this.dateRange);
      }
    });
  }
  changeSelectedDate(event: any) {
    let startDay = event.target.value
      ? new Date(event.target.value)
      : new Date();

    this.startDay = startDay;

    this.getEndDay();
    this.getRosterSchedules();
  }
  openWeatherTemplate(
    template: TemplateRef<any>,
    weatherData: any,
    selectedDate: any
  ) {
    const dayWeather =
      weatherData?.[formatDate(new Date(selectedDate), 'yyyy-MM-dd', 'en_US')];

    this.dialogRef = this.dialogService.open(
      template,
      {
        data: {
          weatherData: dayWeather,
          selectedDate: selectedDate,
          weatherKeys: [
            ...Object.keys(dayWeather)?.filter(
              (key) => !['Condition', 'Description'].includes(key)
            ),
          ],
        },
      },
      this.viewContainerRef
    );
  }
}
