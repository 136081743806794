import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  formatDateTimeStamp,
  isLargeScreen,
  isLargeScreenFun,
} from '../../../../global.variable';
import { AppService } from '../../../app.service';
import { DataCheckService } from '../../../shared/services/data-check.service';
import { LoadingSpinnerService } from '../../../shared/services/loading-spinner.service';
import { OrientationLockService } from '../../../shared/services/orientationlock.service';
import { JobsService } from './jobs.service';
@Component({
  selector: 'gtapp-jobs',
  templateUrl: './jobs.component.html',
  styleUrl: './jobs.component.scss',
})
export class JobsComponent implements OnInit, OnDestroy {
  // redirect components
  notificationRoute: any;
  jobType: any;
  customerId: any;
  siteId: any;
  stalePeriod: any = 3;
  searchRemovalCap: number = 5;
  // manual pagination components
  previous: number = 0;
  pageSize: number = 5;
  totalRows: any;
  pageNum: number = 1;
  totalPages: number = 0;
  mobilePageSize = 5;
  // manual pagination components
  largeView: Boolean = isLargeScreen;
  statusData: any = [];
  userData: any;

  dropDownJobs: any;

  defaultJob: boolean = true;
  listJobData: any = [];

  isLargeScreenSubscription: Subscription = new Subscription();
  // showing data as card list instead of table
  jobCardsDetails: any = {
    // text styling can be updated here

    groupDetails: (row: any) => {
      if (
        row?.status == 'Closed' ||
        row?.status_identity == 2 ||
        row?.status_identity == 3
      ) {
        return {
          opacity: '0.5',
        };
      } else {
        for (let i = 0; i < this.statusData.length; i++) {
          if (this.statusData[i].name === row?.status) {
            return {
              'border-color': `${this.statusData[i].additional_info?.style?.snubbed['border-color']}`,
            };
          }
        }
      }

      return {};
    },
  };
  isFilterApplied: boolean = false;
  showTypeAhead: boolean = true;
  globalFilter: any;

  totalJobCount: any;

  tableStyle = {
    'overflow': 'auto',
    'max-height': '100%',
  };
  otherParams: any = {
    saveFilters: true,
    normalCard: true,
    tableRowStyle: (rowData: any) => {
      if (
        rowData?.status &&
        (rowData?.status == 'Closed' ||
          rowData?.status_identity == 2 ||
          rowData?.status_identity == 3)
      ) {
        return {
          opacity: '0.5',
        };
      }
      return {};
    },
  };

  jobDetailsTableView: any = [];

  sortKeys: any = [];
  dateRange: any;
  searchBody: any;
  loading = false;
  infiniteJobList: any = [];
  infiniteJobListJobCount: number = 0;

  completeStatus: any;
  isDispatchUser: boolean = false;
  isAdmin: boolean = false;
  userGroup: any;
  lastApiResponseHadData: boolean = true;
  lastSearchStr: any;
  apiCallMade: boolean = false;
  jobFilters: boolean = false;
  isSortPopupVisisble: boolean = false;

  etaMinuteTimerInterval: any;
  etaSwitch: boolean = true;

  constructor(
    private jobService: JobsService,
    private spinnerService: LoadingSpinnerService,
    private route: ActivatedRoute,
    private appService: AppService,
    private router: Router,
    private dataCheckService: DataCheckService,
    private cdr: ChangeDetectorRef,
    private orientationService: OrientationLockService
  ) {
    this.customerId = this.route.snapshot.paramMap.get('clientId');
    this.siteId = this.route.snapshot.paramMap.get('siteId');

    this.jobType = this.route.snapshot.fragment;
  }
  threshold = 100;
  hideLoaderOnScroll: boolean = false;

  ngOnInit(): void {
    this.isLargeScreenSubscription =
      this.orientationService.isLargeScreen.subscribe(async (event: any) => {
        if (event) {
          this.largeView = await isLargeScreenFun();
          this.resetFilters(this.jobType);
        }
      });
    this.route.queryParams.subscribe((params: any) => {
      // Access the 'cancel button is clicked from add response page' query parameter
      if (localStorage.getItem('isCancelResponseJob') !== 'true') {
        localStorage.setItem(
          'isCancelResponseJob',
          params['cancel'] === 'true' ? 'true' : 'false'
        );
      }
    });
    this.userData = this.appService.getUserData();
    this.isDispatchUser = this.dataCheckService.isDispatchUser();
    this.isAdmin = this.dataCheckService.isUserAdmin();
    this.pageSize = Number(this.userData?.preferences?.job_page_size || 5);
    // if not admin or dispatcher redirect the user back to dashboard
    if (!this.isAdmin && !this.isDispatchUser) {
      this.router.navigate(['/']).then(() => {
        window.location.reload();
      });
    }
    if (
      (this.isAdmin || this.isDispatchUser) &&
      !this.route.snapshot.fragment
    ) {
      this.jobType = 'defaultJobs';
    }

    this.loadFiltersFromStorage();
  }
  ngOnDestroy(): void {
    this.isLargeScreenSubscription.unsubscribe();
    // if (this.navigationEndSubscription) {
    //   this.navigationEndSubscription.unsubscribe();
    // }
    window.onpopstate = null;

    if (this.etaMinuteTimerInterval) {
      clearInterval(this.etaMinuteTimerInterval);
    }
  }
  getNestedAssignees(rowData: any, maxNumber: any = 2) {
    if (rowData?.assignee?.length > maxNumber) {
      return `${rowData?.assignee?.slice(0, maxNumber)} ...`;
    } else {
      return rowData?.assignee;
    }
  }
  // TODO: Need to change the variable of nebular
  getJobStatuses() {
    this.jobService.getJobParams().subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.statusData = response.statuses;

        this.statusData.push({
          id: '0',
          name: 'Closed',
          additional_info: {
            style: {
              snubbed: {
                'color': 'var(--color-basic-1100)',
                'background-color': 'var(--color-basic-100)',
              },
            },
          },
        });

        this.findDeviceData();
      }
    });
  }

  openAddJobForm() {
    if (
      localStorage.getItem('isCancelResponseJob') === 'true' &&
      this.totalJobCount === 0
    ) {
      localStorage.removeItem('isCancelResponseJob');
      this.router.navigate(['/'], { relativeTo: this.route });
    } else {
      this.router.navigate(['/add-job'], { relativeTo: this.route });
    }
  }
  openIndividualJob(job: any) {
    sessionStorage.setItem(
      this.largeView ? 'largeView' : 'mobileView',
      JSON.stringify(this.jobFilters)
    );
    this.spinnerService.show();

    this.router.navigate([`/job-detail/${job.job_key}`], {
      queryParams: { jId: job?.id },
    });
  }

  onFilterSearch() {
    let body = {
      rows: 5,
      search_str: this.globalFilter,
      ...this.getJobTypeFilter(),
    };
    if (this.lastSearchStr) {
      !this.globalFilter.includes(this.lastSearchStr)
        ? (this.apiCallMade = true)
        : (this.apiCallMade = false);
    } else {
      this.apiCallMade = true;
    }
    if (this.apiCallMade || this.lastApiResponseHadData) {
      this.jobService.fetchJobDetails(body, {}).subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.dropDownJobs = [...response?.data?.values];
          this.lastApiResponseHadData = this.dropDownJobs.length > 0;
          this.lastSearchStr = this.globalFilter;
        }
      });
    }
  }
  onFilterApply(event: any) {
    if (event?.target?.value && event?.target?.value?.length >= 3) {
      this.isFilterApplied = true;
      this.onFilterSearch();
      this.showTypeAhead = true;
    }
    if (
      event === true &&
      this.globalFilter != '' &&
      this.globalFilter != null
    ) {
      this.isFilterApplied = true;
      this.pageNum = 1;
      this.previous = 0;

      this.findDeviceData();
      this.showTypeAhead = false;
    } else if (
      event.key === 'Enter' &&
      this.globalFilter != '' &&
      this.globalFilter != null
    ) {
      this.pageNum = 1;
      this.previous = 0;
      this.isFilterApplied = true;

      this.findDeviceData();
      this.showTypeAhead = false;
    }
  }

  onClickPagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.pageSize = event.pageSize;

    this.findDeviceData();
  }
  onChangePagination(event: any) {
    this.previous = event?.paginationData?.previousRows;
    this.pageNum = event?.paginationData?.pageNum;
    this.pageSize = event?.paginationData?.rows;
    this.otherParams.paginationData = {
      ...event?.paginationData,
    };
    this.findDeviceData();
  }
  sortColumn(body: any) {
    this.sortKeys = body?.sortList || [];

    this.findDeviceData();
  }
  sortField(column: any) {
    this.isSortPopupVisisble = false;
    column['sortOrder'] =
      column?.sortOrder === 'asc'
        ? 'desc'
        : column?.sortOrder === 'desc'
        ? null
        : 'asc';

    this.jobDetailsTableView.forEach((item: any) => {
      if (item?.name !== column?.name) {
        item.sortOrder = null;
      }
      this.cdr.detectChanges();
    });

    let sortKeyValue: any = column?.sortKey
      ? typeof column?.sortKey === 'string'
        ? column?.sortKey
        : column?.name
      : column?.name;
    let sortKey: any =
      column?.sortOrder == 'desc'
        ? `-${sortKeyValue}`
        : column?.sortOrder == 'asc'
        ? sortKeyValue
        : '';

    this.sortKeys = [];
    if (sortKey) this.sortKeys = [sortKey];

    this.totalRows = null;
    this.previous = 0;
    this.pageNum = 1;

    this.findDeviceData();
  }

  searchColumn(event: any) {
    this.dateRange = event?.date || null;
    this.searchBody = event?.searchParams || {};

    this.getLargeDevicesJobs();
  }
  findDeviceData() {
    // function to dynamically find screen width and determine whther data is to be processed for mobile or large devices

    this.jobCardsDetails.individualDetails = this.getJobDetailView();
    this.jobDetailsTableView = this.getJobDetailView();

    if (this.largeView) {
      this.getLargeDevicesJobs();
    } else {
      this.pageSize = this.mobilePageSize;
      // mobile devices
      this.infiniteJobList = [];
      this.infiniteJobListJobCount = 0;

      this.getInfinteLoadData();
    }
  }
  getJobTypeFilter() {
    let body: any = {};

    if (this.customerId) {
      body['company_id'] = this.customerId;
    } else if (this.siteId) {
      body['site_id'] = this.siteId;
    } else {
      if (this.jobType == 'staleJobs') {
        body.stale_days = this.stalePeriod;
      } else if (this.jobType == 'unassignedJobs') {
        body.unassigned_jobs = 1;
      } else if (this.jobType == 'defaultJobs') {
        body.desktop_default_list = 1;
        this.defaultJob = true;
      } else if (this.jobType == 'allJobs') {
        this.defaultJob = false;
      }

      this.router.navigate(['/jobs'], { fragment: this.jobType });
    }
    if (this.sortKeys) body.sort_key = this.sortKeys;
    if (this.globalFilter) {
      body.search_str = this.globalFilter;
    }

    if (this.searchBody) body.search_filter = this.searchBody;

    return body;
  }
  getInfinteLoadData() {
    if (this.loading) {
      return;
    }

    if (this.largeView) {
      return;
    }

    let param: any = {};
    let body = this.getJobTypeFilter();

    body.rows = this.mobilePageSize;

    this.loading = true;

    if (this.previous) {
      body['previous'] = this.previous;
    }
    if (!this.hideLoaderOnScroll) {
      this.spinnerService.show();
    }
    this.jobService.fetchJobDetails(body, param).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.totalRows = response?.data?.total_size;

        this.totalPages = Math.ceil(this.totalRows / this.pageSize);
        let newList = response?.data?.values;
        this.infiniteJobList = this.infiniteJobList.concat(newList);
        this.totalJobCount = response?.['job_count'] || 0;
        this.previous = this.previous + this.mobilePageSize;
        this.infiniteJobListJobCount =
          this.infiniteJobListJobCount + newList?.length;
        if (
          this.totalJobCount === 0 &&
          (this.isAdmin || this.isDispatchUser === true)
        ) {
          this.openAddJobForm();
        }
        this.loading = false;

        this.saveJobFilters(body, param);
        if (this.totalJobCount > 0) {
          this.appService.updateOnBoardingValue('responseJob');
        }
      }
      this.spinnerService.hide();
    });
  }
  getMapMobileList() {
    if (this.largeView) {
      return;
    }

    let param: any = {};
    let body = this.getJobTypeFilter();

    body.rows = this.mobilePageSize;

    if (this.previous) {
      body['previous'] = this.previous;
    }

    this.spinnerService.show();
    this.jobService.fetchJobDetails(body, param).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.totalJobCount = response?.['job_count'] || 0;
        if (
          this.totalJobCount === 0 &&
          (this.isAdmin || this.isDispatchUser === true)
        ) {
          this.openAddJobForm();
        }
        this.listJobData = response?.data?.values;
        this.totalRows = response?.data?.total_size;
        this.totalPages = Math.ceil(this.totalRows / this.pageSize);

        this.saveJobFilters(body, param);
      }
      this.spinnerService.hide();
    });
  }

  getLargeDevicesJobs() {
    let param: any = { large_devices: 1 };
    let body = this.getJobTypeFilter();

    if (this.pageSize) {
      body['rows'] = this.pageSize;
      let userData = this.appService.getUserData();
      if (userData?.preferences) {
        userData.preferences.job_page_size = this.pageSize;
        this.appService.setUserData(userData);
      }
    }
    if (this.previous) {
      body['previous'] = this.previous;
    }

    this.spinnerService.show();

    this.jobService.fetchJobDetails(body, param).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.totalJobCount = response?.['job_count'] || 0;
        if (
          this.totalJobCount === 0 &&
          (this.isAdmin || this.isDispatchUser === true)
        ) {
          this.openAddJobForm();
        }
        this.listJobData = response?.data?.values;

        this.totalRows = response?.data?.total_size;
        this.totalPages = Math.ceil(this.totalRows / this.pageSize);
        this.otherParams.paginationData = {
          totalRows: this.totalRows,
          previousRows: this.previous,
          rows: this.pageSize,
          pageNum: this.pageNum,
          totalPages: this.totalPages,
        };
        if (this.totalJobCount > 0) {
          this.appService.updateOnBoardingValue('responseJob');
        }

        this.saveJobFilters(body, param);
      }
      this.spinnerService.hide();
    });
  }
  resetFilters(jobType = 'jobs') {
    this.globalFilter = null;
    this.previous = 0;
    this.pageNum = 1;
    this.jobType = jobType;
    this.totalRows = null;
    this.siteId = null;
    this.customerId = null;

    this.customerId = null;
    this.otherParams.paginationData = {
      totalRows: this.totalRows,
      previousRows: this.previous,
      rows: this.pageSize,
      pageNum: this.pageNum,
      totalPages: this.totalPages,
    };
    this.router.navigate(['/jobs'], { fragment: this.jobType });
    this.findDeviceData();
  }

  showAllJobsDesktop() {
    this.jobType = this.jobType == 'allJobs' ? 'defaultJobs' : 'allJobs';
    this.defaultJob = !this.defaultJob;
    this.resetFilters(this.jobType);
  }
  saveJobFilters(body: any, params: any) {
    this.jobFilters = {
      ...body,
      ...params,
      jobType: this.jobType,
      jobDetailsTableView: this.jobDetailsTableView,
    };

    this.updateInTransitInterval();
  }
  loadFiltersFromStorage() {
    const filterKey: string = this.largeView ? 'largeView' : 'mobileView';
    const filters = JSON.parse(sessionStorage.getItem(filterKey) || '{}');
    if (Object.keys(filters)?.length) {
      this.otherParams.loadFilters = true;
      this.customerId = filters?.company_id;
      this.jobType = filters?.jobType;
      this.stalePeriod = filters?.stale_days;
      this.searchBody = filters?.search_filter || {};
      this.globalFilter = filters?.search_str;
      this.sortKeys = filters?.sort_key || [];

      this.jobDetailsTableView.forEach((item: any) => {
        filters?.jobDetailsTableView?.forEach((column: any) => {
          if (item?.name == column?.name) {
            item.sortOrder = column?.sortOrder;
          }
        });
      });
    }
    sessionStorage.removeItem(filterKey);
    this.getJobStatuses();
  }
  loadMoreJobs() {
    this.hideLoaderOnScroll = true;
    this.getInfinteLoadData();
  }
  isActiveResponseJob(jobData: any) {
    return !(
      jobData?.status == 'Closed' ||
      jobData?.status_identity == 2 ||
      jobData?.status_identity == 3 ||
      jobData?.roster_scheduler
    );
  }
  getJobStatusStyle(jobData: any, isLargeView = false) {
    let customStyle: any = {};
    if (isLargeView) {
      this.statusData?.forEach((element: any) => {
        if (element.name === jobData?.status) {
          customStyle = {
            'color': `${element.additional_info?.style?.snubbed?.color}`,
            'background-color': `${element.additional_info?.style?.snubbed['background-color']}`,
            'text-align': 'center',
            'white-space': 'pre-line',
          };
        }
      });
    } else {
      this.statusData?.forEach((element: any) => {
        if (element.name === jobData?.status) {
          customStyle = {
            'color': `${element.additional_info?.style?.snubbed?.color}`,
            'background-color': `${element.additional_info?.style?.snubbed['background-color']}`,
            'border': '1px solid',
            'border-radius': '5px',
            'padding': '0 0.5rem',
            'float': 'right',
            'font-size': 'small',
            'text-align': 'center',
            'white-space': 'pre-line',
          };
        }
      });
    }
    if (jobData?.guard_eta > 0) {
      const diffInTime = jobData?.guard_eta - new Date().getTime();
      if (diffInTime < 0) {
        // this implies that eta provided by user has passed and he is late
        customStyle['background-color'] = 'var(--bs-danger)';
      }
    }

    return customStyle;
  }

  updateEtaValue() {
    this.etaSwitch = !this.etaSwitch;

    this.listJobData = JSON.parse(JSON.stringify(this.listJobData));
    this.infiniteJobList = JSON.parse(JSON.stringify(this.infiniteJobList));
  }
  updateInTransitInterval() {
    const jobDataList = this.largeView
      ? this.listJobData
      : this.infiniteJobList;

    const isThereETAInList = jobDataList?.some(
      (job: any) => job?.guard_eta > 0
    );
    if (isThereETAInList) {
      this.etaMinuteTimerInterval = setInterval(() => {
        this.updateEtaValue();
      }, 5000);
    } else if (this.etaMinuteTimerInterval) {
      clearInterval(this.etaMinuteTimerInterval);
    }
  }
  getJobDetailView() {
    if (this.largeView) {
      return [
        {
          header: 'Updated',
          name: 'modified_date',
          datetimeObj: true,
          dateRangeKey: 'modified_date',
          sortKey: 'modified_date',
          conditionalColumnStyle: (row: any) => {
            const modifiedDate = new Date(row?.modified_date).getTime();
            const staleDate = new Date().setDate(
              new Date().getDate() - this.stalePeriod ? this.stalePeriod : 3
            );

            if (
              modifiedDate < staleDate &&
              row?.status !== 'Closed' &&
              (row?.status_identity != 2 || row?.status_identity != 3)
            ) {
              return {
                'color': 'var(--bs-white)',
                'background-color': 'var(--bs-danger)',
              };
            }
            return {};
          },
        },
        {
          header: 'Job ID.',
          sortKey: 'job_key',
          searchKey: 'job_key',
          tableColumnStyle: {
            'white-space': 'pre-line',
            'font-size': '0.8rem',
          },
          nestedValue: (row: any) => {
            let appendList = [row?.job_key];
            if (row?.ext_job_id) appendList.push(`[${row?.ext_job_id}]`);

            return appendList.join('\n');
          },
        },
        {
          header: 'Response Type',
          name: 'response_type',
          sortKey: 'response_type',
          tableColumnStyle: {
            'white-space': 'pre-line',
          },
          suffixNestedValue: (row: any) => {
            return row?.monitoring_company
              ? `\n[${row?.monitoring_company}]`
              : null;
          },
        },
        {
          header: 'Site',
          sortKey: 'site_name',
          name: 'site_name',
          searchKey: 'site_name',
          tableColumnStyle: {
            'white-space': 'pre-line',
          },
          suffixNestedValue: (row: any) => {
            return `\n[${row?.company}]`;
          },
          suffixStyle: { 'font-size': '0.8rem' },
        },

        {
          header: 'Address',
          name: 'sites',
          sortKey: 'sites',
          searchKey: 'sites',
        },
        {
          header: 'Guard',
          name: 'assignee',
          nestedValue: (row: any) => {
            return this.getNestedAssignees(row);
          },

          searchKey: 'assignee',
        },
        {
          header: 'Status',

          nestedValue: (row: any) => {
            let appendList = [];
            if (row?.status) appendList.push(row?.status);
            if (row?.guard_eta && Number(row?.guard_eta) > 0)
              appendList.push(this.getEtaValue(row));

            return appendList.join('\n');
          },
          dropDownList: () => {
            return this.statusData;
          },

          conditionalColumnStyle: (row: any) => {
            return this.getJobStatusStyle(row, true);
          },
          dropdownKey: 'status_id',
        },
      ];
    } else {
      let cardDetailList: any = [
        {
          nestedValue: (row: any) => {
            let appendList = [];
            if (row?.status) appendList.push(row?.status);
            if (row?.guard_eta && Number(row?.guard_eta) > 0)
              appendList.push(this.getEtaValue(row));

            return appendList.join('\n');
          },
          conditionalRowStyle: (row: any) => {
            return this.getJobStatusStyle(row, false);
          },
        },

        {
          nestedValue: (row: any) => {
            return this.isActiveResponseJob(row)
              ? ''
              : 'UPDATED: ' +
                  String(
                    formatDateTimeStamp(
                      row?.modified_date,
                      'd MMM y HH:mm',
                      'en_US'
                    )
                  );
          },
          conditionalRowStyle: (row: any) => {
            if (this.isActiveResponseJob(row)) {
              return {};
            }
            if (
              new Date(row?.modified_date).getTime() <
              new Date().setDate(new Date().getDate() - this.stalePeriod)
            ) {
              return {
                'background-color': 'var(--bs-red)',
                'color': 'var(--bs-gray-100)',
                'width': 'fit-content',
                'border': '1px solid #333',
                'border-radius': '3px',
                'font-size': 'small',
                'padding-left': '0.5rem',
                'padding-right': '0.5rem',
              };
            }
            return {
              'border': '1px solid #333',
              'border-radius': '3px',
              'font-size': 'small',
              'padding-left': '0.5rem',
              'padding-right': '0.5rem',
            };
          },
        },

        {
          name: 'response_type',
          cardRowStyle: { 'font-weight': 'bold' },
        },

        {
          name: 'site_name',
          cardRowStyle: { 'font-weight': 'bold' },
          suffixStyle: { 'font-weight': 'normal' },
          suffixNestedValue: (row: any) => {
            return ` [${row?.company}]`;
          },
        },
        // Address
        {
          name: 'sites',
          cardRowStyle: { 'font-size': 'small' },
        },

        {
          nestedValue: (row: any) => {
            if (row?.ext_job_id) {
              return `${row?.job_key} [${row?.ext_job_id}]`;
            } else {
              return row?.job_key;
            }
          },
          cardRowStyle: {
            'font-size': 'small',
          },
        },
      ];

      if (this.isAdmin || this.isDispatchUser) {
        cardDetailList.push({
          nestedValue: (row: any) => {
            const isAssigneeEmpty =
              !row?.assignee ||
              row?.assignee.every((item: any) => item.trim() === '');
            if (isAssigneeEmpty) {
              return 'No Guard Assigned';
            } else if (row?.assignee) {
              return row.assignee.join(', '); // Join names with a comma and space
            }
          },
          conditionalRowStyle: (row: any) => {
            const isAssigneeEmpty =
              !row?.assignee ||
              row?.assignee.every((item: any) => item.trim() === '');
            if (isAssigneeEmpty) {
              return {
                'color': 'var(--bs-red)',
                'width': 'fit-content',
                'font-size': '1rem',
                'font-weight': '700',
                'padding-top': '0.1rem',
              };
            }
            return { 'margin-top': '0.5rem' };
          },
        });
      }
      return cardDetailList;
    }
  }
  getEtaValue(jobData: any) {
    if (this.etaSwitch) {
      return `ETA: ${formatDateTimeStamp(
        jobData?.guard_eta,
        'h:mm a',
        'en_US'
      )} `;
    } else {
      const diffInTime = jobData?.guard_eta - new Date().getTime();

      const diffInMinutes = Math.floor(diffInTime / (1000 * 60)) + 1;
      return diffInTime >= 0
        ? `${diffInMinutes} minutes`
        : `${diffInMinutes * -1} mins late`;
    }
  }
}
