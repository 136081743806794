import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DeviceInfoService } from './device-info.service';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  socket!: WebSocket;
  webSocketSubject = new Subject();
  reconnectCounter: number = 0;
  appStatus: boolean = true;

  constructor(private deviceInfoService: DeviceInfoService) {
    this.connect();

    this.socket.onerror = (error) => {
      // to store the error in the postgres db
      throw new Error(String(error));
    };
    this.deviceInfoService.deviceInfoSubject.subscribe((value: any) => {
      if ('appStatus' in value) {
        if (value?.appStatus && !this.appStatus) {
          this.connect();
        }
        this.appStatus = value?.appStatus;
      }
    });
  }
  connect(timeout = 1000) {
    const userData = JSON.parse(localStorage.getItem('userData') || '{}');

    const host = window.location.host;
    const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';

    this.socket = new WebSocket(
      `${protocol}://${host}/websocket/?token=${userData?.access}`
    );
    this.socket.onopen = () => {};
    this.socket.onmessage = (event: any) => {
      this.webSocketSubject.next(JSON.parse(event?.data));
    };
    this.socket.onclose = () => {
      this.reconnectCounter += 1;
      setTimeout(() => {
        if (this.appStatus) this.connect(timeout + timeout);
      }, timeout);
    };
  }
}
