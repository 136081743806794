<div class="col-12 col-lg-6 pt-2">
  <!--create data view-->
  <ng-container *ngIf="!selectedHandOverLogData && !selectedHandOverLogId">
    <ng-container *ngIf="selectedEventCount < 1">
      <div class="col-6 mb-3">
        <button
          class="btn btn-lg btn-danger w-100"
          [routerLink]="['/hand-over-log']"
        >
          Delete Draft
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="currentStep == 1">
      <ng-container *ngIf="selectedEventCount > 0">
        <div class="row row-cols-2 mb-3">
          <div class="col">
            <button class="btn btn-success btn-lg w-100" (click)="sendLater()">
              Send Later
            </button>
          </div>
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-primary w-100"
              [disabled]="selectedEventCount < 1"
              (click)="currentStep = 2"
            >
              Preview
            </button>
          </div>
        </div>
      </ng-container>
      <div class="text-end" *ngIf="alreadySentEventIds?.length">
        <button
          type="button"
          class="btn btn-primary btn-sm mb-2"
          (click)="alreadySentEventIds = []"
        >
          Show All
        </button>
      </div>

      <table class="table">
        <tbody *ngFor="let event of eventsDataList">
          <tr
            (click)="selectUnselectEvents(event)"
            class="cursor-pointer"
            *ngIf="!alreadySentEventIds?.includes(event?.id)"
          >
            <td>
              <div
                (click)="onOpenFile(event); $event.stopImmediatePropagation()"
                class="float-end"
                *ngIf="event?.event_action != 1"
              >
                <i class="fa-solid fa-image fa-2x text-primary"></i>
              </div>
              <div class="d-flex mb-2">
                <div class="me-1">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [checked]="event?.selected"
                  />
                </div>
                <div class="me-3 small">
                  <div>
                    {{ event?.updated_at | customDate : "d MMM y HH:mm" }}
                  </div>
                  <div class="text-secondary">
                    {{ event?.event_action === 1 ? "[Comment]" : "[Photo]" }}
                  </div>
                </div>
                <div class="small">
                  <div>
                    {{ event?.site_name }}
                  </div>
                  <div class="text-secondary">[{{ event?.company_name }}]</div>
                </div>
              </div>
              <div class="gt-multi-line ms-4 mb-3">
                {{ event?.detail }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>
    <ng-container *ngIf="currentStep == 2">
      <div class="row row-cols-2 mb-3">
        <div class="col">
          <button
            class="btn btn-lg btn-secondary w-100"
            (click)="currentStep = 1"
          >
            Back
          </button>
        </div>
        <div class="col">
          <button
            type="button"
            class="btn btn-lg btn-primary w-100"
            [disabled]="selectedEventCount < 1"
            (click)="currentStep = 3; getRecipients()"
          >
            Send To
          </button>
        </div>
      </div>
      <table class="table">
        <tbody *ngFor="let event of eventsDataList">
          <tr
            (click)="selectUnselectEvents(event)"
            class="cursor-pointer"
            *ngIf="event?.selected"
          >
            <td>
              <div
                (click)="onOpenFile(event); $event.stopImmediatePropagation()"
                class="float-end"
                *ngIf="event?.event_action != 1"
              >
                <i class="fa-solid fa-image fa-2x text-primary"></i>
              </div>
              <div class="d-flex mb-2">
                <div class="me-3 small">
                  <div>
                    {{ event?.updated_at | customDate : "d MMM y HH:mm" }}
                  </div>
                  <div class="text-secondary">
                    {{ event?.event_action === 1 ? "[Comment]" : "[Photo]" }}
                  </div>
                </div>
                <div class="small">
                  <div>
                    {{ event?.site_name }}
                  </div>
                  <div class="text-secondary">[{{ event?.company_name }}]</div>
                </div>
              </div>
              <div class="gt-multi-line mb-3">
                {{ event?.detail }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>
    <ng-container *ngIf="currentStep == 3">
      <div class="row row-cols-2 g-2 mb-4 pt-2">
        <div class="col">
          <button
            type="button"
            class="btn btn-lg btn-secondary w-100"
            (click)="recipientList = []; currentStep = 2"
          >
            Back
          </button>
        </div>
        <div class="col">
          <button
            type="button"
            class="btn btn-lg btn-primary w-100"
            [disabled]="!selectedRecipients?.length"
            (click)="currentStep = 4"
          >
            Next
          </button>
        </div>
      </div>
      <div class="mb-2">Select Recipients:</div>

      <div *ngIf="recipientList.length > 10" class="mb-1">
        <gtapp-auto-complete
          [data]="recipientSearchList"
          placeHolder="Guard Name"
          searchKeyword="full_name"
          [itemTemplate]="assigneeListTemplate"
          (inputChanged)="searchAssignee($event)"
          (selected)="addSelectedAssignee($event)"
        >
        </gtapp-auto-complete>
      </div>

      <ng-template #assigneeListTemplate let-item>
        <div>
          {{ item?.full_name }}
        </div>
      </ng-template>
      <div class="overflow-assignne" id="assigneeList">
        <table class="table my-2">
          <tbody>
            <tr
              *ngFor="let assignee of recipientList"
              class="cursor-pointer"
              (click)="addRemovessignee(assignee)"
            >
              <td class="py-4">
                <div class="d-flex">
                  <div>
                    <div class="form-floating">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        [checked]="assignee?.selected"
                      />
                    </div>
                  </div>
                  <div class="flex-grow-1 ms-2">
                    {{ assignee?.full_name }}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="mt-3"
        *ngIf="
          handOverLogRecipientsTotalPages > 1 &&
          handOverLogRecipientsTotalCount != recipientList?.length
        "
      >
        <button
          type="button"
          class="btn btn-secondary btn-lg"
          (click)="onLoadMoreAssignees()"
        >
          Load More
        </button>
      </div></ng-container
    >
    <ng-container *ngIf="currentStep == 4">
      <div class="row row-cols-2 g-2 mb-2 mt-2">
        <div class="col">
          <button
            type="button"
            class="btn btn-lg btn-secondary w-100"
            (click)="currentStep = 3"
          >
            Back
          </button>
        </div>
        <div class="col">
          <button
            type="button"
            class="btn btn-lg btn-success w-100"
            [disabled]="handOverLogCreated"
            (click)="createhandOverLog()"
          >
            Send
          </button>
        </div>
      </div>
      <div class="col">
        <textarea
          class="form-control"
          placeholder="Additional Information (Optional)"
          cols="20"
          rows="10"
          wrap="hard"
          maxlength="900"
          type="text"
          [(ngModel)]="handOverLogDescription"
        ></textarea>
      </div>
    </ng-container>
  </ng-container>
  <!--detailed data view-->
  <ng-container *ngIf="selectedHandOverLogData && selectedHandOverLogId">
    <div class="mt-3">
      <div>
        <dl>
          <dt>From</dt>
          <dd>{{ selectedHandOverLogData?.created_by?.full_name }}</dd>
        </dl>
      </div>
      <div>
        <dl>
          <dt>To</dt>
          <dd>{{ selectedHandOverLogData?.received_by?.full_name }}</dd>
        </dl>
      </div>
      <div>
        <dl>
          <dt>Date</dt>
          <dd>
            {{
              selectedHandOverLogData?.updated_at | customDate : "d MMM y HH:mm"
            }}
          </dd>
        </dl>
      </div>

      <div>
        <dl class="d-flex" *ngIf="selectedHandOverLogData?.description">
          <div class="flex-grow-1 gt-multi-line">
            <dt>Notes</dt>
            <dd>
              {{ selectedHandOverLogData?.description }}
            </dd>
          </div>
          <!-- <div>
      <button class="btn btn-success btn-sm" (click)="commentOnLog()">
        Comment
      </button>
    </div> -->
        </dl>
      </div>
      <div>
        <dl>
          <dd>
            <table class="table">
              <tbody *ngFor="let event of selectedHandOverLogData?.events">
                <tr>
                  <td>
                    <div
                      (click)="onOpenFile(event)"
                      class="float-end"
                      *ngIf="event?.event_action != 1"
                    >
                      <i class="fa-solid fa-image fa-2x text-primary"></i>
                    </div>
                    <div class="d-flex mb-1 small">
                      <div class="me-3">
                        <div>
                          {{ event?.updated_at | customDate : "d MMM y HH:mm" }}
                        </div>
                        <div class="stext-secondary">
                          {{
                            event?.event_action === 1 ? "[Comment]" : "[Photo]"
                          }}
                        </div>
                      </div>
                      <div>
                        <div>
                          {{ event?.site_name }}
                        </div>
                        <div class="text-secondary">
                          [{{ event?.company_name }}]
                        </div>
                      </div>
                    </div>
                    <div class="gt-multi-line ms-1 mb-3">
                      {{ event?.detail }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </dd>
        </dl>
      </div>
    </div>
  </ng-container>
</div>
